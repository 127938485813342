import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import { breakpoints } from '../styles/Variables';
import WorkVideo from './WorkVideo';
import { BsArrowsFullscreen } from 'react-icons/bs';

// Video imports
import videoMusa from '../video/musa-balthazaurus-scroll.mp4'
import gifMusa from '../gifs/musa-balthazaurus-scroll.gif'
import videoCrmInvoicing from '../video/crm-invoicing-balthazaurus-scroll.mp4'
import gifCrmInvoicing from '../gifs/crm-invoicing-balthazaurus-scroll.gif'
import videoGatoEstudio from '../video/gato-estudio-balthazaurus-scroll.mp4'
import gifGatoEstudio from '../gifs/gato-estudio-balthazaurus-scroll.gif'
import videoAnachrony from '../video/anachrony-balthazaurus-scroll.mp4'
import gifAnachrony from '../gifs/anachrony-balthazaurus-scroll.gif'
import videoLoop from '../video/loop-balthazaurus-scroll.mp4'
import gifLoop from '../gifs/loop-balthazaurus-scroll.gif'
import videoChanceToChallenge from '../video/chance-to-challenge-balthazaurus-scroll.mp4'
import gifChanceToChallenge from '../gifs/chance-to-challenge-balthazaurus-scroll.gif'
import videoAmeena from '../video/ameena-balthazaurus-scroll.mp4'
import gifAmeena from '../gifs/ameena-balthazaurus-scroll.gif'
import videoMaGleason from '../video/ma-gleason-balthazaurus-scroll.mp4'
import gifMaGleason from '../gifs/ma-gleason-balthazaurus-scroll.gif'
import videoBaseAgency from '../video/base-agency-balthazaurus-scroll.mp4'
import gifBaseAgency from '../gifs/base-agency-balthazaurus-scroll.gif'
import videoOye from '../video/oye-balthazaurus-scroll.mp4'
import gifOye from '../gifs/oye-balthazaurus-scroll.gif'
import videoWarehouseRentals from '../video/warehouse-rentals-balthazaurus-scroll.mp4'
import gifWarehouseRentals from '../gifs/warehouse-rentals-balthazaurus-scroll.gif'
import videoLaSala from '../video/la-sala-balthazaurus-scroll.mp4'
import gifLaSala from '../gifs/la-sala-balthazaurus-scroll.gif'
import videoSilveroute from '../video/silveroute-balthazaurus-scroll.mp4'
import gifSilveroute from '../gifs/silveroute-balthazaurus-scroll.gif'
const video = {
  'musa': videoMusa,
  'musa-gif': gifMusa,
  'crm-invoicing': videoCrmInvoicing,
  'crm-invoicing-gif': gifCrmInvoicing,
  'gato-estudio': videoGatoEstudio,
  'gato-estudio-gif': gifGatoEstudio,
  'anachrony': videoAnachrony,
  'anachrony-gif': gifAnachrony,
  'loop': videoLoop,
  'loop-gif': gifLoop,
  'chance-to-challenge': videoChanceToChallenge,
  'chance-to-challenge-gif': gifChanceToChallenge,
  'ameena': videoAmeena,
  'ameena-gif': gifAmeena,
  'ma-gleason': videoMaGleason,
  'ma-gleason-gif': gifMaGleason,
  'base-agency': videoBaseAgency,
  'base-agency-gif': gifBaseAgency,
  'oye': videoOye,
  'oye-gif': gifOye,
  'warehouse-rentals': videoWarehouseRentals,
  'warehouse-rentals-gif': gifWarehouseRentals,
  'la-sala': videoLaSala,
  'la-sala-gif': gifLaSala,
  'silveroute': videoSilveroute,
  'silveroute-gif': gifSilveroute,
}

const WorkProjectStyles = styled.article`
  position: relative;
  font-size: var(--fsTitle2);
  margin-bottom: 6rem;
  z-index: 5;

  h2 {
    font-size: var(--fsTitle1);
  }

  p {
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: ${breakpoints.breakLg}) {
      margin-bottom: 0.5em;
    }
  }

  .tiny {
    /* margin-left: -0.5em; */
  }

  &:nth-child(odd) {
    .tiny {
      /* margin-left: 0;
      margin-right: -0.5em; */
    }
  }

  img,
  video {
    width: 100%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  }

  img {
    transition: all 0.5s;

    &:hover {
      transform: scale(1.025);
    }
  }

  .expandButton {
    transition: all 0.5s;
    width: 0.75em;

    &:hover {
      transform: scale(1.25);
    }
  }

  .projectNavigation {
    display: none;
    position: absolute;
    background: none;
    border: none;
    top: calc(50% - 1em);
    transition: all 0.5s;
    font-size: 1.25em;
    z-index: 1;

    &:hover {
      transform: scale(1.25);
    }

    &.previous {
      left: 1em;
    }

    &.next {
      right: 1em;
    }

    // Small screens
    @media (max-width: ${breakpoints.breakMd}) {
      top: 80%;

      &.previous {
        left: 0;
      }

      &.next {
        right: 0;
      }
    }
  }

  @media (min-width: ${breakpoints.breakLg}) {
    display: grid;
    /* grid-gap: 2rem; */
    align-items: center;

    header {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 1em;
    }

    &:nth-child(even) {
      grid-template-columns: var(--col7) auto;

      header {
        order: 2;
      }

      p {
        padding-right: calc(100% - var(--maxParagraphWidth));
      }
    }

    &:nth-child(odd) {
      grid-template-columns: auto var(--col7);

      header {
        text-align: right;
      }

      p {
        padding-left: calc(100% - var(--maxParagraphWidth));
      }
    }
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 0;
    top: 0;
    width: 100%;
    left: 0;
    background-color: transparent;
    transition: background-color 1s;
    z-index: 1;
  }

  // ==== Expanded mode ====
  &.expanded {
    position: fixed;
    z-index: 15;
    min-height: 100vh;
    display: flex;
    grid-gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0 var(--col1);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: var(--greyVeryDark);
    color: var(--black);

    &::before {
      height: 100%;
      background-color: var(--grey);
    }

    .projectNavigation {
      display: inline-block;
    }

    .category {
      display: none;
    }

    button.close {
      color: var(--black);
      border: none;
      font-size: 2rem;
      /* position: absolute;
      left: 50%;
      top: 0.5rem; */
      background: none;
      z-index: 1;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.25);
      }
    }

    header {
      text-align: center;
      z-index: 1;
      background-color: transparent;
      padding: 0;
    }

    video {
      z-index: 1;
    }

    &:nth-child(odd),
    &:nth-child(even) {
      header {
        order: 2;
      }
    }

    h2 {
      font-size: var(--fsTitle2);
    }

    p {
      padding: 0;
      max-width: 26em;
      margin: 0 auto;
      font-size: var(--fsBody);

      em {
        font-size: var(--fsTiny);
      }
    }
    
    // Expanded – mobile only
    @media (max-width: ${breakpoints.breakSm}) {
      header {
        padding: 0 0.5em;
      }
    }

    // Expanded – tablet and desktop
    @media (min-width: ${breakpoints.breakMd}) {
      h2 {
        font-size: var(--fsTitle1);
      }

      p {
        font-size: var(--fsTitle2);
      }
    }

    // Expanded – desktop
    @media (min-width: ${breakpoints.breakLg}) {
      padding: 0 var(--col2) 0 var(--col2);
    }

    // Expanded – very short window
    @media (max-height: 400px) {
      grid-gap: 0;

      header {
        display: none;
      }

      button.close {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    // Expanded – short and wide window
    @media (max-height: 700px) and (min-width: ${breakpoints.breakMd}) {
      header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        text-align: left;
        width: 100%;

        .body {
          text-align: right;
        }

        h2 {
          font-size: var(--fsTitle2);
        }

        p {
          max-width: unset;
          font-size: var(--fsBody);
        }
      }
    }
  }
`;

export default function WorkProject({
  project, 
  previousProject, 
  nextProject, 
  currentProjectId, 
  setCurrentProjectId
}) {
  const expanded = currentProjectId === project._id;

  const doExpand = (project) => {
    document.body.classList.add('modal')
    setCurrentProjectId(project._id)
  }

  const doClose = () => {
    document.body.classList.remove('modal')
    setCurrentProjectId(null)
  }

  return <WorkProjectStyles className={expanded ? 'expanded' : ''}>
    {expanded && <button type="button" className="close" onClick={doClose} title="close">
      &times;
    </button>}
    <header>
      <h2>
        <span className="tiny category">{project.categories[0]?.title.toLowerCase()}<br /></span>
        {project.title}
      </h2>
      {!expanded && <>
        <p className="subtitle">
          {project.subtitle}
        </p>
        <BsArrowsFullscreen onClick={() => doExpand(project)} className="expandButton" />
      </>}
      {expanded && <div className="body">
        <BlockContent blocks={project._rawBody} />
      </div>}
    </header>
    {!expanded && <img src={video[`${project.slug.current}-gif`]} alt={project.title} onClick={() => doExpand(project)} />}
    {expanded && <>
      <WorkVideo src={video[project.slug.current]} heightPercent={project.heightPercent} />
      
      {previousProject && <button type="button" className="projectNavigation previous" onClick={() => doExpand(previousProject)} title="Previous">←</button>}
      {nextProject && <button type="button" className="projectNavigation next" onClick={() => doExpand(nextProject)} title="Next">→</button>}

    </>}
    {/* <div className="videoWrapper"
      dangerouslySetInnerHTML={{
          __html: project.videoEmbed
        }}></div> */}
  </WorkProjectStyles>
}