import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components';
import { breakpoints } from '../styles/Variables';
import WorkProject from '../components/WorkProject';

export const query = graphql`
  query {
    posts: allSanityPost(sort: { fields: order }) {
      nodes {
        _id
        title
        subtitle
        slug {
          current
        }
        _rawBody
        heightPercent
        categories {
          title
        }
      }
    }
  }
`;

const WorkPageStyles = styled.div`
  --maxParagraphWidth: 16em;
  padding-top: 5rem;

  @media (min-width: ${breakpoints.breakMd}) {
    padding-top: 8rem;
    padding-bottom: 6rem;
  }

  &::after {
      content: '';
      position: fixed;
      display: block;
      height: 6rem;
      left: 0;
      top: 0;
      width: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
      z-index: 9;
    }

  h1 {
    position: fixed;
    left: 25%;
    top: 1rem;
    width: 50%;
    text-align: center;
    z-index: 10;
    mix-blend-mode: difference;
  }
`

const WorkPage = ({data}) => {
  const [currentProjectId, setCurrentProjectId] = useState();
  
  // Get yr data from data.nodes
  const lastPostIndex = data.posts.nodes.length - 1;
  return <WorkPageStyles>
    <h1>Work</h1>
    {data.posts.nodes.map((post, index) => <WorkProject 
      key={post._id} 
      project={post}
      previousProject={index > 0 ? data.posts.nodes[index - 1] : null} 
      nextProject={index < lastPostIndex ? data.posts.nodes[index + 1] : null} 
      currentProjectId={currentProjectId}
      setCurrentProjectId={setCurrentProjectId}
    />)}
  </WorkPageStyles>
}

export default WorkPage