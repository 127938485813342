import React from 'react'
import styled from 'styled-components';
import { breakpoints } from '../styles/Variables';

const WorkVideoStyles = styled.div`
  position: relative;
  height: 0;
  transition: all 0.5s;
  z-index: 1;

  video {
    width: 100%;
    transition: all 0.5s;
  }

  @media (min-width: ${breakpoints.breakMd}) {
    width: 110vh;
    max-width: 100%;
    height: unset;
    padding-bottom: 0 !important;

    video {
      width: 110vh;
    max-width: 100%;
    }
  }
`;

export default function WorkVideo({src, heightPercent}) {
  return <WorkVideoStyles style={{
    paddingBottom: `${heightPercent || '56.25'}%`,
  }}>
    <video playsInline loop autoPlay>
      <source src={src} type="video/mp4" />
    </video>
  </WorkVideoStyles>
}